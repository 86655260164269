.containerAll {
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: rgb(230, 230, 230);
}
.judul {
    position: relative;
    height: 20%;
    background-color: white;
    font-family: Georgia;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 10vw;
    font-size: 5vw;
}
.judul div{
    position: absolute;
  background-color: black;
    height: 6vw;
    width: 6vw;
margin-left: 1.5%;
left: 0;
display: flex;
justify-content: center;
align-items: center;
border-radius: 3vw;
cursor: pointer;
}
.judul div img{
    height: 80%;
    width: 80%;
}
.isi {
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.kotakForm {
    height: 80%;
    width: 30%;
    background-color: white;
    border-radius: 2vw;
}
.judulForm {
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2f69fd;
    box-sizing: border-box;
    border-bottom: solid 2px rgb(230, 230, 230);
    font-size: 2vw;
}
.isiForm {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.containerInputBiasa{
    height: 80%;
    width: 100%;
   
    overflow-y: auto;
}
.inputBiasa {
    margin-top: 5%;
    height: 11%;
    width: 80%;
    margin-left: 10%;
}
.visibleAfiliator {
    display: block;
    margin-top: 5%;
    height: 10%;
    width: 80%;
}
.notVisibleAfiliator {
    display: none;
    margin-top: 5%;
    height: 10%;
    width: 80%;
}
.visibleWaktu {
    margin-bottom: 3%;
    display: block;
    margin-top: 5%;
    height: 11%;
    width: 80%;
    margin-left: 10%;
}
.notVisibleWaktu {
    display: none;
    margin-top: 5%;
    
    height: 10%;
    width: 80%;
    margin-left: 10%;
}
.visibleGender {
    margin-bottom: 3%;
    display: block;
    margin-top: 5%;
    height: 11%;
    width: 80%;
    margin-left: 10%;
}
.notVisibleGender {
    display: none;
    margin-top: 5%;
    height: 10%;
    width: 80%;
    margin-left: 10%;
}
.keterangan {
    margin-top: 2%;
    height: 8%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
  
}
.divKirim {
    margin-top: 1%;
    margin-bottom: 3%;
    height: 10%;
    width: 50%;
    background-color: #2f69fd;
    border-radius: 2vw;
    box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.1);
}
