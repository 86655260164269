/*body {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
  }
  
  .menu-table, .receipt {
    width: 45%;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
  }*/

  .containerAll{
   
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .divMenuTable{
    height: 100%;
    width: 45%;
   
  }
  .divReceipt{
    height: 100%;
    width: 45%;
  }
  .notVisibleLapisan{
    display: none;
  }
  .visibleLapisan{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: black;
    opacity: 0.3;
  }
  .notVisiblePopUp{
    display: none;
  }
  .visiblePopUp{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20%;
    height: 20%;
    margin-left: 40%;
    margin-top: 20%;
    background-color: white;
    border-radius: 1vw;
    z-index: 2;
  }
  .judulPopUp{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 100%;
    font-weight: bold;
  }
  .judulPopUp img{
    position: absolute;
    right: 0;
    height: 40%;
    width: 6%;
    margin-right: 3%;
    cursor: pointer;
  }
  .spinnerPopUp{
    margin-top: 5%;
    height: 20%;
    width: 80%;
    margin-left: 10%;
  }
  .keteranganPopUp{
    height: 10%;
    margin-top: 3%;
    color: red;
    text-align: center;
  }
  .buttonKirim{
    height: 20%;
    width: 50%;
    margin-left: 25%;
    border-radius: 1vw;
    margin-top: 3%;
    cursor: pointer;
    color: white;
    background-color: #4CAF50;
  }

  