.tabelDatabase{
    height: 80%;
width: 50%;

    font-size: 20px;

   
}

.tabel {
  
    width: 100%;
    border-collapse: collapse;
}
.tabel tr{
    width: 100%;

}
.tabel th{
    text-align: center;
}
.tabel th:nth-child(1){
width: 30%;
}
.tabel th:nth-child(2){
    width: 40%;
    }
.tabel th:nth-child(3){
        width: 30%;
        }
.tabel td{
    text-align: center;
}

.tdAction{
    text-align: center;
    height: 100%;
    
}
.tdAction button{
border-radius: 10px;
width: 80%;
cursor: pointer;
color: white;
    background-color: orange;
}

