.containerAll{
    height: 78%;
    width: 100%;
    display: flex;
}
.containerKiri{
    height: 100%;
    width: 25%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: solid 1px #ddd ;
}
.date{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 8%;
 
}
.date input{
  cursor: pointer;
  width:100%;
}
.date img{
  margin-left: 25%;
  height: 100%;
  width:50%;
 
}
.tulisanCashFlow{
    margin-top: 3%;
    font-size: 1.8vw;
    font-family:Georgia;
}
.tableCashFlow{
  
    width: 80%;
 
    overflow-y: auto;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
    
  }

  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .table th {
    background-color: #f2f2f2; 
  }

  .divTotalSaldo{
    width: 80%;
    height: 10%;
    background-color: #f2f2f2; 
    display: flex;
    align-items: center;
    border-bottom-left-radius: 1vw;
    border-bottom-right-radius: 1vw;
  }
  .divTotalSaldo div:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    font-weight: bold;
    height: 100%;
  }
  .divTotalSaldo div:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    font-weight: bold;
    height: 100%;
  }





.containerKanan{
    display: flex;
    height: 100%;
    width: 75%;
  
    justify-content: space-around;
    align-items: center;
}
.containerKananPemasukan{
    width: 45%;
    height: 90%;
    
}
.containerKananPengeluaran{
    width: 45%;
    height: 90%;
    
}
.judulDetailGym{
height: 10%;
width: 100%;
display: flex;
align-items: center;
justify-content: center;

font-family:Georgia;
}
.judulDetailZumba{
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family:Georgia;
    }
    .isiDetailGym{
        height: 90%;
        width: 100%;
       overflow-y: auto;
        }
        .tableGym {
            width: 100%;
            border-collapse: collapse;
           
            
          }
          .trSticky{
            position: sticky;
            top: 0;
          }
          
          .tableGym th, .table td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center;
          }
          .tableGym tr{
            text-align: center;
          }
          
          .tableGym th {
            background-color: #f2f2f2; 
          }
          .thKeterangan{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            
          }
          .thKeterangan img{
        
            position: absolute;
            top: 45%;
            right: 2%;
            height: 20%;
            width: 10%;
            cursor: pointer;
          }
          .divThKeteranganVisible{
            display: block;
            position: absolute;
            top: 100%;
            height: 200%;
            width: 100%;
            background-color:rgb(224, 224, 224);
          }
          .divThKeteranganNone{
            display: none;
          }
          .divThKeteranganVisible div{
            border-bottom: solid 1px black;
            cursor: pointer;
          }
          
          