.containerAll{
    height: 78%;
    width: 100%;
   display: flex;
  align-items: center;
   flex-direction: column;
 position: relative;

}
.judul{
    display: flex;
    margin-top: 2%;
    height: 10%;
    width: 40%;
 
    position: relative;
    justify-content: center;
    align-items: center;
    position: relative;
}

.date{
position: absolute;

right: 0;
border-radius: 1vw;

}
.date input{
  
}
.tulisanJudul{
font-size: 2vw;
}
.tulisanNew{
    color: white;
    height: 3vw;
    width:3vw;
    position: absolute;
    right: 3%;
    background-color: rgb(196, 178, 178);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2vw;
    cursor: pointer;
}
  .divTable{
    height:60% ;
    width: 40%;

    overflow-y: auto;
  }
  .divTotalRekap{
    height: 20%;
    width:40% ;
    background-color: #f2f2f2; 
    border-bottom-left-radius: 2vw;
    border-bottom-right-radius: 2vw;
  padding-left: 1%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  }
  .divTotalRekap div:nth-child(3){
    font-weight: bold;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
    
  }
  .trSticky{
    position: sticky;
    top: 0;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .table th {
    background-color: #f2f2f2; 
  }
  .notVisibleDivInput{
    display: none;
  }
  
  .visibleDivInput{
    position: absolute;
    right: 1%;
    margin-top: 7%;
    height: 60%;
    width: 25%;
display: flex;
flex-direction: column;
align-items: center;
border-radius: 1vw;
    border: solid 1px rgb(224, 224, 224)
    
  }
  .visibleDivInput input{
    border-radius: 1vw;
    margin-top: 3%;
    height: 10%;
    width: 70%;
  }
  .visibleDivInput button{
    border-radius: 1vw;
    margin-top: 3%;
    height: 12%;
    width: 40%;
  }
  .thKeterangan{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .thKeterangan img{
    position: absolute;
    top: 45%;
    right: 0;
    height: 20%;
    width: 10%;
    cursor: pointer;
  }
  .divThKeteranganVisible{
    display: block;
    position: absolute;
    top: 100%;
    height: 200%;
    width: 100%;
    background-color:rgb(224, 224, 224);
  }
  .divThKeteranganNone{
    display: none;
  }
  .divThKeteranganVisible div{
    border-bottom: solid 1px black;
    cursor: pointer;
  }