.containerAll{
    height: 78%;
    width: 100%;
    display: flex;
}
.containerKiri{
    height: 100%;
    width: 25%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: solid 1px #ddd ;
}
.divAfiliatorGym{
    height: 45%;
    width: 80%;
    margin-top: 2%;
}
.judulAfiliatorGym{
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:Georgia;

}
.isiAfiliatorGym{
  height: 90%;
    width: 100%;

    overflow-y: auto;
  }
  .tableAfiliator {
    width: 100%;
    border-collapse: collapse;
    
  }

  
  .tableAfiliator th, .tableAfiliator td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .tableAfiliator th {
    background-color: #f2f2f2; 
  }
  .containerKanan{
    height: 100%;
    width: 75%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divDetailZumba{
    height: 90%;
    width: 80%;

  }
  .judulDetail{
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:Georgia;
  }
  .isiDetail{
    height: 90%;
    width: 100%;
    overflow-y: auto;
  }
  .trSticky{
    position: sticky;
    top: 0;
  }
  
