.containerAll{
    height: 100vh;
    width: 100%;
   position: relative;
   overflow: hidden;
}
.inputBayangan{
    background-color: transparent;
    border: none;
    outline: none;
 caret-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
   z-index: 2;
  
}
.tulisanAttendance{
    height: 20%;
    width: 100%;
    /*border-bottom: solid 2px rgb(230, 230, 230);*/
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5vw;
    font-family:Georgia;
    position: relative;
    background-color: rgba(0,0,0,255);
    color: #ff7d26;
}
.tulisanAttendance div{
    position: absolute;
    left: 1%;
    height: 7vw;
    width: 7vw;
    background-color: rgba(0,0,0,255);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4vw;
    cursor: pointer;
    z-index: 2;
}
.tulisanAttendance div img{
    height: 80%;
    width: 90%;
  
}
.divKotakTap{
    height: 30%;
    width: 50%;
    border-radius: 2vw;
   position: absolute;
   top: 35%;
   left: 25%;
   

}
.divKotakTap input{
    border: none;
   background-color: transparent; 
    font-size: 3vw;
    height: 100%;
    width: 100%;
    border-radius: 2vw;
text-align: center;
caret-color: transparent; /* Menyembunyikan kursor */
outline: none; /* Opsional: Menghapus outline pada input saat fokus */
color: white;
}
.divSudahScan{
        height: 40%;
        width: 100%;
     
       position: absolute;
       top: 35%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
.sudahScanAtas{
    font-family:Georgia;
    color:#2f69fd;
    font-size: 3vw;
    margin-bottom: 2%;
}
.sudahScanBawah{
    font-family:Helvetica;
    font-size: 3vw;
    color: white;
}
.divIsi{
    
display: flex;
align-items: center;
justify-content: center;
    height: 80%;
    width: 100%;
    background-color: rgba(0,0,0,255);
}
.gantiFoto{
    position: absolute;
    top: 0;
    opacity: 0.15;
    height: 100%;
    width: 100%;
    z-index: 1;
}
.notVisiblePembayaranFreelance{
    display: none;
}
.visiblePembayaranFreelance{
    display: block;
    position: absolute;
    top: 0;
    height: 20%;
    width: 20%;
    margin-left: 40%;
    margin-top: 18%;
    background-color: white;
    z-index: 2;
    border-radius: 1vw;
}
.judulPembayaranFreelance{
    position: relative;
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   font-weight: bold;
}
.spinnerPembayaranFreelance{
    height: 20%;
    margin-top: 5%;
    width: 80%;
    margin-left: 10%;
}
.keteranganPembayaranFreelance{
    color: red;
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
}
.buttonPembayaranFreelance{
    margin-left: 25%;
    margin-top: 4%;
    height: 20%;
    width: 50%;
    background-color: #ff7d26;
    border-radius: 2vw;
}
.logoSilang{
    position: absolute;
    height: 60%;
    width: 8%;
    cursor: pointer;
    right: 0;
    margin-right: 3%;
}
