.containerAll{
    height: 76%;
    width: 100%;
 
}
/* div atas */
.divAtas{
height: 5%;
width: 100%;
display: flex;
justify-content: center;
position: relative;
margin-top: 1%;


}
.divLogoExcels{
margin-top: -0.5%;
position: absolute;
margin-left: 35%;
 height: 90%;
 width: 10%;
 cursor: pointer;
display: flex;
justify-content: space-around;
}
.excelPusat{
    height: 90%;
    width: 20%;
}
.excelPusat div{
    text-align: center;
    font-size: 12px;
}
.excelPusat img{
    height: 100%;
    width: 100%;
}
.excelDaerah{
    height: 90%;
    width: 20%;

}
.excelDaerah div{
    text-align: center;
    font-size: 12px;
}
.excelDaerah img{
    height: 100%;
    width: 100%;
}


.divAtas input{
    height: 100%;
    width:7% ;
 
    
}
.divLogoPanah{
    height: 100%;
    width: 4%;
    margin-left: 1%;
    margin-right: 1%;
}
.divAtas div img{
    height: 100%;
    width: 100%;
}
/* div isi */
.divIsi{
    margin-top: 1%;
width: 100%;
height: 73%;
display: flex;
justify-content: center;
}
.divData{
    height: 100%;
    width: 40%;
  
}

.divTable{
    max-height: 80%;
    width: 100%;
    overflow: auto;
}
.table {
    width: 100%;
    border-collapse: collapse;
    
  }

  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .table th {
    background-color: #f2f2f2; 
  }
.trSticky{
    position: sticky;
    top: 0;
}
.divTotalPendapatan{
    width: 100%;
    display: flex;
    align-items: center;
    height:10% ;
    border-bottom: solid 1px #ddd;
}
.divTotalPendapatan div{
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: #f2f2f2; 
}
.divTotalPajak{
    width: 100%;
    display: flex;
    align-items: center;
    height:10% ;
 
}
.divTotalPajak div{
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: #f2f2f2; 
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}