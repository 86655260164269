.containerAll{
    height: 78%;
    width: 100%;
    display: flex;
}
.containerKiri{
    height: 100%;
    width: 25%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: solid 1px #ddd ;
}

.divTableMember{
  width: 80%;
}
.judulMember{
  height: 10%;
  width: 80%;
  margin-top: 3%;
    font-size: 1.8vw;
    font-family:Georgia;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tableMember {
    width: 100%;
    border-collapse: collapse;
    
  }

  
  .tableMember th, .tableMember td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .tableMember th {
    background-color: #f2f2f2; 
  }
  .judulVisitor{
    height: 10%;
    width: 80%;
    margin-top: 10%;
      font-size: 1.8vw;
      font-family:Georgia;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .date{
    display: flex;
    position: absolute;
    top: -30%;
    left: 0;
    height: 40%;
    width: 40%;
   
  }
  .date input{
    height: 100%;
    width: 100%;
  }
  
.containerKanan{
  width: 75%;
  height: 100%;
 
}
  .containerAllAttendanceVisitor{
    height: 100%;
    width:100% ;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .divAVKiri{
    height: 95%;
    width: 45%;

  }
  .judulDivAVKiri{
    height: 10%;
width: 100%;
display: flex;
align-items: center;
justify-content: center;

font-family:Georgia;
  }
  .isiDivAVKiri{
    height: 90%;
    width: 100%;
    overflow-y: auto;
  }
  .tableAVKiri {
    width: 100%;
    border-collapse: collapse;
    
  }

  
  .tableAVKiri th, .tableAVKiri td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .tableAVKiri th {
    background-color: #f2f2f2; 
  }
  .trStickyAVKiri{
    position: sticky;
    top: 0;
  }
  /* */
  .divAVKanan{
    height: 95%;
    width: 45%;
   
  }

  /* */
  .containerAllDataIdCard{
    height: 100%;
    width: 100%;
   
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .divDIKiri{
    height: 95%;
    width: 45%;
   
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .divDatabaseKaryawan{
    height: 30%;
    width: 100%;
   margin-bottom: 1%;
  }
  .divDatabaseMember{
    height: 70%;
    width: 100%;
   margin-bottom: 1%;
  }
  .judulDatabase{
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:Georgia;
    
  }
  .isiDatabase{
    height: 90%;
    width: 100%;
   overflow-y: auto;
   margin-bottom: 1%;
  
  }
  .tableDatabase{
    width: 100%;
    border-collapse: collapse;
    
  }
  .tableDatabase th, .tableDatabase td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    font-size: 1vw;
  }
  
  .tableDatabase th {
    background-color: #f2f2f2; 
    font-size: 1vw;
  }
  .trSticky{
    position: sticky;
    top: 0;
  }
  .divDIKanan{
    height: 95%;
    width: 45%;
   
  }
  /* div kanan - div detail */
  .divDetailKaryawanDatabase{
    height: 100%;
    width: 100%;
   
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .tanggalDetailDatabase{
    height: 4%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .tanggalDetailDatabaseKaryawan{
    
    height: 4%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tanggalDetailDatabase input{
    height: 100%;
  }
  .tanggalDetailDatabaseKaryawan input{
    height: 100%;
  }
  .divPanah{
    height: 100%;
    width: 10%;
    margin-left: 3%;
    margin-right: 3%;
  }
  .divPanah img{
    height: 100%;
    width: 100%;
  }
  .divAtasDetailDatabase{
height: 47%;
width: 100%;

  }
  .judulDivAtasDetailDatabase{
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:Georgia;
    
  }
  .isiDivAtasDetailDatabase{
    height: 90%;
    width: 100%;
   overflow-y: auto;
    
  }
  .tableDetailDatabase {
    width: 100%;
    border-collapse: collapse;
    
  }

  
  .tableDetailDatabase  th, .tableDetailDatabase  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .tableDetailDatabase  th {
    background-color: #f2f2f2; 
  }
  .trSticky{
    position: sticky;
    top: 0;
  }
  /* */
  .divBawahDetailDatabase{
    height: 47%;
    width: 100%;
    
  }
  