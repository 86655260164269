.containerAll{
    height: 78%;
    width: 100%;
    display: flex;
}
.divKiri{
width: 25%;
height: 100%;

display: flex;
flex-direction: column;
align-items: center;
border-right:solid 1px #ddd;
}
.judulTrainer{
    height: 10%;
    width: 80%;
    margin-top: 2%;
    font-size: 2vw;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.judulTrainer img{
    cursor: pointer;
    position: absolute;
    margin-top: 2%;
    margin-left: 1%;
    left: 0;
    height:2.5vw;
    width: 2.5vw;
}
.table{
    margin-top: 2%;
    width: 80%;
    border-collapse: collapse;
}

  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .table th {
    background-color: #f2f2f2; 
  }
  .divKananWhite{
    background-color: white;
    width: 75%;
    height: 100%;
 
    display: flex;
    justify-content: center;
    align-items: center;
}
.containerDetailTrainer{
    height: 100%;
    width: 100%;
    display: flex;
   
}
.containerDetailTrainerKiri{
    width: 50%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
}
.judulDetailTrainerKiri{
    height: 10%;
    width: 80%;
    
    font-size: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.isiDetailTrainerKiri{
    width: 90%;
    height: 80%;
    overflow-y: auto;
}
.tableDetailTrainerKiri{
    margin-top: 2%;
    width: 100%;
    border-collapse: collapse;
}
.tableDetailTrainerKiri th, .tableDetailTrainerKiri td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
.tableDetailTrainerKiri th {
    background-color: #f2f2f2; 
  }
.trSticky{
    position: sticky;
    top: 0;
}


.visibleContainerDetailTrainerKanan{
    display: block;
    width: 50%;
    height: 100%;
   border-left: solid 1px #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.notVisibleContainerDetailTrainerKanan{
   display: none;
    
}
.divKananAbu{
    background-color: rgb(230, 230, 230);
    width: 75%;
    height: 100%;
 
    display: flex;
    justify-content: center;
    align-items: center;
}
.containerNewForm{
    background-color: white;
    height: 80%;
    width:35%;
    border-radius: 2vw;
    overflow-y: auto;
    
}
.judulNewForm{
    
    top: 0;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    font-family: Georgia;
    border-bottom: solid 1px #ddd;
    margin-bottom: 1%;

}
.isiNewForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    height:120%;
    width: 100%;
 
    
}
.isian{
   margin-top: 5%;
    height: 10%;
    width: 80%;
    box-sizing: border-box;
   
}
.isianSpinner{
    margin-top: 5%;
     height: 10%;
     width: 80%;
     box-sizing: border-box;
 }
 .visibleAlasan{
    display: block;
    margin-top: 5%;
    height: 10%;
    width: 80%;
    box-sizing: border-box;
 }
 .notVisibleAlasan{
    display: none;
    margin-top: 5%;
    height: 10%;
    width: 80%;
    box-sizing: border-box;
 }
 .merahColorKeterangan{
    margin-top: 5%;
    height: 3%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
 }
 .hitamColorKeterangan{
    margin-top: 5%;
    height: 3%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
 }
 .divKirim {
    margin-top: 5%;
    height: 9%;
    width: 50%;
    background-color: #2f69fd;
    border-radius: 2vw;
    box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.1);
}
.jedaAkhir{
    height: 5%;
    width: 100%;
}
.tdKlik{
    cursor: pointer;
}
.visiblePopUp{
    display: block;
    height: 100%;
    width: 100%;
    background-color: transparent;
    position: absolute;
    top: 0;
    z-index: 2;
  
  
}
.notVisiblePopUp{
   display: none;
}
.visiblePelapis{
    display: block;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background-color: grey;
    position: absolute;
    top: 0;
    z-index: 1;
  
  
}
.notVisiblePelapis{
   display: none;
}
.dialogPopUp{
    height: 30%;
    width: 30%;
    background-color: white;
    margin-left: 35%;
    margin-top: 15%;
    border-radius: 2vw;
   
}
.buttonHadir{
    cursor: pointer;
}
.divJudulPopUp{
    font-size: 1.5vw;
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-bottom: solid 1px black;
    position: relative;
}
.divNamaPeserta{
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: blue;
    margin-top: 2%;
}
.divScan{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30%;
    color: grey;
    font-size: 1.5vw;
}
.divKeteranganPopUp{
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
}
.logoSilang{
    position: absolute;
    right: 0;
    height: 40%;
    width: 4%;
    margin-right: 3%;
    cursor: pointer;
}
.inputPopUp{
    background-color: transparent;
    outline: none;
    border: none;
}