.containerAll{
    height: 78%;
    width: 100%;
    display: flex;
}
.containerKiri{
    height: 100%;
    width: 25%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: solid 1px #ddd ;
}
.judulContainerKiri{
    margin-top: 1%;
    font-size: 20px;
    font-weight: bold;
}
.judulDatabase{
    margin-top: 5%;
    cursor: pointer;
}



.containerKanan{
  width: 75%;
  height: 100%;


}
.isiContainerKanan{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
   
}
.judulTabelDatabase{
    height: 8%;
    margin-top: 1%;
    font-size: 20px;
    font-weight: bold;
   
}

.tabel{
    margin-top: 5%;
    width: 100%;
    border-collapse: collapse;
}

  