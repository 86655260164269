.containerAll{
    height: 100vh;
    width: 100%;
   position: relative;
   background-color: rgb(230, 230, 230);
}
.judul{
    height: 20%;
    background-color: white;
    font-family:Georgia;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 10vw;
    font-size: 5vw;
}
.isi{
  width: 100%;
 height: 80%;
 display: flex;
 justify-content: center;
 align-items: center;
}
.divLogin{
    width: 30%;
    height: 50%;
    background-color: white;
    border-radius: 2vw;
}
.judulDivLogin{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    height: 20%;
    width: 100%;
    color:#2f69fd;
  
    box-sizing: border-box;
    border-bottom: solid 2px rgb(230, 230, 230) ;
}
.pilihanDivLogin{
    height: 30%;
    width: 100%;
   margin-top: 2%;
    justify-content:center;
    display: flex;
    align-items: center;
}
.divPilihanKaryawan{
    margin-right: 3%;
    height: 90%;
    width:20%;
    border:solid 1px rgb(230, 230, 230) ;
    border-radius: 0.5vw;
    box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.1);
}
.divPilihanOwner{
    margin-left: 3%;
    height: 90%;
    width:20%;
    border:solid 1px rgb(230, 230, 230) ;
    border-radius: 0.5vw;
    box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.1);
}
.divPassword{
    margin-top: 8%;
    width: 100%;
    height: 10%;
   
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.divPassword input{
    border-radius: 0.4vw;
    box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.1);
    border:solid 2px rgb(230, 230, 230) ;
    outline: none;
    box-sizing: border-box;
    height: 100%;
    width: 50%;
    margin-right: 1%;
}
.divKirim{
    border-radius: 0.4vw;
    height: 100%;
    width: 15%;
    background-color:#2f69fd;
    color: white;
    box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.1);
    
}
.divKeterangan{
    text-align: center;
    color: red;
    margin-top: 3%;
}


