.containerAll{
    height: 100%;
    width: 100%;
}
.divGambar{
    height: 70%;
    width: 70%;
    margin-left: 15%;
    cursor: pointer;
}
.divGambar img{
    height: 100%;
    width: 100%;
}
.divNama{
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}